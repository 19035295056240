import * as React from "react"
import styled from "styled-components"

import Layout from "../components/layout"
import Seo from "../components/seo"
import NotFoundImage from '../images/mirage-page-not-found.png'

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <NotFoundContent>
      <img src={NotFoundImage} alt="Página não existe" width="180px" />
      <h1>404: Nada Por Aqui</h1>
      <p>Você caiu numa página que não existe...</p>
    </NotFoundContent>
  </Layout>
)

export default NotFoundPage

const NotFoundContent = styled.div`
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 6rem;
  text-align: center;
  
  h1 {
    margin-top: 2rem;
  }
`
